import React, { useEffect, useRef, useState } from "react";
import "./Footer.css";
// import "./Footer.css";
import { throttle } from "lodash";

const Footer = ({ setThemeMode, theme }) => {
  const foot = useRef();
  const [sticks, setSticks] = useState(30);
  const [selectedStick, setSelectedStick] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(-2);
  const [parts, setParts] = useState(sticks / 4);
  const [componentWidth, setComponentWidth] = useState(
    Math.ceil(foot?.current?.offsetWidth)
  );

  useEffect(() => {
    setParts(sticks / 4);
    // Check if the device supports touch
    const isTouchDevice =
      "ontouchstart" in window || navigator.msMaxTouchPoints;
    if (isTouchDevice) {
      setHoveredIndex(sticks + 2);
    }
  }, [sticks]);

  useEffect(() => {
    _setDefaultStick();
  }, []);

  useEffect(() => {
    const handleResize = throttle(() => {
      const newWidth = foot?.current?.offsetWidth;

      if (newWidth !== componentWidth) {
        setSticks(Math.ceil(newWidth / 30));
        setComponentWidth(newWidth);
        _setDefaultStick();
      }
    }, 200);

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [componentWidth, foot]);

  const _setDefaultStick = () => {
    if (theme === "dark") {
      setSelectedStick(sticks / 2);
    }
    if (theme === "light") {
      setSelectedStick(2);
    }
  };

  const _setStickHeight = (i) => {
    const even = i % 2 === 0;
    const odd = i % 2 !== 0;
    const isHover = i === hoveredIndex;
    const isSelected = selectedStick === i;
    const isNeighbourSelected =
      i === selectedStick - 1 || i === selectedStick + 1;
    const isNeighbourHover = i === hoveredIndex - 1 || i === hoveredIndex + 1;

    if (isSelected) return "sel";
    if (isHover) return "hov";
    if (isNeighbourSelected || isNeighbourHover) return "middle";
    if (even) return "even";
    if (odd) return "odd";
  };

  const _setStickColor = (i) => {
    if (i <= parts || i >= parts * 3) return "light";
    if (i > parts && i < parts * 3) return "dark";
  };

  const _renderSticks = () => {
    let box = [];
    for (let i = 0; i <= sticks; i++) {
      box.push(
        <div className={`stick`} key={i}>
          <span
            className={` ${_setStickHeight(i)} ${_setStickColor(i)} `}
          ></span>
        </div>
      );
    }
    return box;
  };

  const _handleRangeSelect = (e) => {
    setSelectedStick(+e.target.value);
    setThemeMode(_setStickColor(+e.target.value), e);
  };

  const _handleMouseMove = (event) => {
    const rangeInput = event.target;
    const rect = rangeInput.getBoundingClientRect();
    const percentage = (event.clientX - rect.left) / (rect.width - 1);
    const value = Math.round(percentage * sticks);

    setHoveredIndex(value);
  };
  const _handleMouseLeave = () => {
    setHoveredIndex(sticks + 2);
  };

  return (
    <footer id="contact">
      <div className="centered-container">
        <div className="content">
          <div
            className="footer-header"
            style={{
              fontFamily: "var(--font-mono)",
            }}
          >
            What is next?
          </div>
          <h2>Get in Touch</h2>
          <p>
            Ready to collaborate? Let's chat and explore how I can help you
            achieve your development goals.
          </p>
          <div>
            <a
              href="mailto:contact@denielsolyom.com"
              className="button"
              style={{ margin: "auto" }}
            >
              Let's Build Something Amazing Together
            </a>
          </div>
        </div>
      </div>
      <div className="slider-container" ref={foot}>
        <div className="slider-sticks">{_renderSticks()}</div>
        <input
          type="range"
          className="slider-input"
          value={selectedStick}
          max={sticks}
          onMouseMove={_handleMouseMove}
          onMouseLeave={_handleMouseLeave}
          onChange={_handleRangeSelect}
        ></input>
      </div>
    </footer>
  );
};

export default Footer;
