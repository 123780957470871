import React from "react";
import { FiFacebook, FiInstagram, FiLinkedin } from "react-icons/fi";
import "./Sides.css";

const Sides = () => {
  return (
    <div className="sides-wrapper">
      <ul className="left">
        <li className="header-element" data-animation="navigation">
          <a href="https://www.linkedin.com/in/deniel-solyom/" aria-label="My linkedin page">
            <FiLinkedin />
          </a>
        </li>
        <li className="header-element" data-animation="navigation">
          <a href="https://www.facebook.com/deniel.solyom" aria-label="My Facebook page">
            <FiFacebook />
          </a>
        </li>
        <li className="header-element" data-animation="navigation">
          <a href="https://www.instagram.com/denielsolyom" aria-label="My Instagram page">
            <FiInstagram />
          </a>
        </li>
      </ul>
      <div className="right header-element" data-animation="navigation">
        <a href="mailto:contact@denielsolyom.com">contact@denielsolyom.com</a>
      </div>
    </div>
  );
};

export default Sides;
