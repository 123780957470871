import React, { useEffect, useRef, useState } from "react";
import "./Home.css";

import SelfImage from "../assets/solyom-deniel-devid-javascript-developer-project-base-b2b.webp";
import TabsWrapper from "../components/TabsWrapper";
import Reviews from "../components/Reviews";
import { TypeAnimation } from "react-type-animation";
import { Link } from "react-scroll";
import lightHexagon from "../assets/hexagon-light.svg";
import darkHexagon from "../assets/hexagon-dark.svg";
import Footer from "../components/Footer";

const reviews = [
  {
    name: "Kudor-Dani Jenő",
    link: "https://www.linkedin.com/in/kudor-dani-jen%C5%91-29aba6171/",
    company: "HCL Technologies",
    review: [
      "I recommend Deniel Sólyom, with whom I have had the pleasure of working for one and a half years, time in which he has been an integral part of our front-end development team.",
      "He has consistently demonstrated his expertise in front-end development, including proficiency in JavaScript, LitHtml, HTML, CSS and more. He has also shown excellent problem-solving skills and an ability to work well under pressure. During this time, Deniel has contributed significantly to several projects. He was responsible for implementing the user interface for these projects, which have been praised and received positive feedback from both our team and our clients.",
      "Deniel is a dedicated and hardworking individual who is always willing to go above and beyond to ensure that projects are completed on time and to the highest standard. In addition to his technical skills, Deniel is also a valuable asset to any team because of his commitment to excellence and his dedication to continuous learning. He is always willing to help others, is eager to learn new things, and is able to adapt quickly to new challenges.",
      "I highly recommend Deniel for any software development position and I have no doubt that he would be a valuable asset to your team.",
    ],
  },
  {
    name: "Tamas Kolcsey",
    link: "https://www.linkedin.com/in/tamas-kolcsey-764040b1/",
    company: "Exotic-K",
    review: [
      "I had the privilege of working with Deniel at Exotic-K, where he held the role of Web Technician. Over the course of his tenure, Deniel's dedication, expertise, and continual drive for excellence were evident in every project he undertook.",
      "In the rapidly-evolving world of web development, Deniel showcased a remarkable aptitude for JavaScript, making significant contributions to our team's efforts and consistently delivering solutions that were both innovative and robust. But what truly set him apart was his holistic understanding of the web environment. His foundation in HTML and CSS was solid, and his knowledge in network administration added an extra layer of depth to the insights he brought to the table.",
      "Outside of his technical prowess, Deniel was a joy to work with. His collaborative spirit, clear communication, and positive attitude made every project not just successful but also enjoyable.",
      "I wholeheartedly recommend Deniel for any organization looking for a top-tier JavaScript Engineer with a well-rounded skill set. Any team would be fortunate to have him onboard.",
    ],
  },
  {
    name: "Jonathán Sólyom",
    link: "https://www.linkedin.com/in/solyom-jonathan-miklos/",
    company: "Freelancing",
    review: [
      "Deniel is a great person who has a clear vision and an exceptional motivational spirit. I worked with him on various projects and he proven a great teamwork. I can confidently attest to their exceptional skills, dedication, and contributions to our projects.",
      "He consistently delivered high-quality work and demonstrated a remarkable ability to tackle complex frontend development challenges with efficiency and creativity. I can confidently recommend Deniel to any Frontend Developer position, because he is exceptionally good at what he does.",
      "He works really hard, always tries to come up with new and better ways to do things, and makes sure he keeps up with all the latest trends in his field. Plus, Deniel is great at talking to people and working together with others. He's the kind of person who can fit in well with any team and be a valuable part of it. Based on my experience, Deniel is not just a talented developer, but also a trustworthy and dependable professional who will definitely help any company he works for to do well and grow.",
    ],
  },
  {
    name: "Razvan Sipoteanu",
    link: "https://www.linkedin.com/in/razvan-sipoteanu-820155196/",
    company: "HCL Technologies",
    review: [
      "I had the pleasure of working closely with Deniel Sólyom for one year, and I wholeheartedly recommend him. He is an exceptional professional with a remarkable ability to deliver high quality software.",
      "Deniel consistently impressed me with his dedication and attention to detail. He contributed significantly to our team's success.",
    ],
  },
];

const Home = ({ setThemeMode, theme }) => {
  const [showBtn, setShowBtn] = useState(false);
  const [tempRew, setTempRew] = useState(reviews);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  // Determine screen size and update state
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth !== screenSize) {
        setShowBtn(window.innerWidth <= 1300);
        setScreenSize(window.innerWidth);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    setShowBtn(window.innerWidth <= 1300);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (showBtn) {
      setTempRew([reviews[0]]);
    } else {
      setTempRew(reviews);
    }
  }, [showBtn]);

  const toggleShowAll = () => {
    setTempRew(reviews);
    setShowBtn(false);
  };

  return (
    <main id="intro" data-theme={theme}>
      <section
        className="introduction"
        data-animation="intro-section"
        data-intersecting="true"
      >
        <h2 className="description-heading ">Hi, my name is</h2>
        <h1 className="hero">Deniel Sólyom</h1>
        <h3 className="hero-tagline ">
          I bring your digital projects to{" "}
          <span className="highlight-word">life</span>
          <span id="point">.</span>
        </h3>
        <p>
          <TypeAnimation
            sequence={[
              "",
              2000,
              "As a freelance JavaScript developer/contractor with a passion for modern and lightning-fast web applications. I'm here to partner with your business.",
              1000,
              "As a freelance JavaScript developer/contractor with a passion for modern and lightning-fast web applications. I'm here to partner with your clients.",
              1000,
              "As a freelance JavaScript developer/contractor with a passion for modern and lightning-fast web applications. I'm here to build your next app.",
              1000,
            ]}
            wrapper="span"
            speed={70}
          />
        </p>
        <Link
          to="contact"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="button"
          aria-label="Contact me"
        >
          Let's Work Together
        </Link>
        <div className="hexagon">
          <img src={theme === "dark" ? lightHexagon : darkHexagon} />
        </div>
      </section>
      <section className="about" id="about" data-intersecting="true">
        <div className="section-header">
          <h2>.aboutMe()</h2>
        </div>
        <div className="about-me-description">
          <div>
            <div>
              <p>
                Hi, I'm Deniel. My journey to programming started back in 2014
                when I first changed the color of a word to yellow in HTML and
                CSS (I've been using it ever since).
              </p>
              <p>
                Jokes aside, after I got more serious about programming , I
                worked for startups, multinational companies, world famous
                banks, and freelanced.
              </p>
              <p>
                Here are a few technologies I’ve been working with recently:
              </p>
            </div>
            <ul className="my-technologies list">
              <li>JavaScript</li>
              <li>React</li>
              <li>litHTML</li>
              <li>Node.js</li>
              <li>Express</li>
              <li>Mocha / Chai</li>
            </ul>
          </div>
          <div className="image-frame">
            <img
              src={SelfImage}
              className="selfImg"
              alt="Deniel Devid Solyom JavaScript Developer"
            />
          </div>
        </div>
      </section>
      <section className="experience" id="experience" data-intersecting="true">
        <div className="section-header">
          <h2>.experience()</h2>
        </div>
        <div className="list-of-employees">
          <TabsWrapper></TabsWrapper>
        </div>
        <div className="hexagon">
          <img src={theme === "dark" ? lightHexagon : darkHexagon} />
        </div>
      </section>
      <section
        className="testimonials"
        id="testimonials"
        data-intersecting="true"
      >
        <div className="section-header">
          <h2>.testimonials()</h2>
        </div>
        <div className="reviews-wrapper">
          {tempRew.map((review, i) => (
            <Reviews key={i} review={review}></Reviews>
          ))}
        </div>

        {showBtn ? (
          <div className="card-container">
            <div id="show-more-btn" className="button" onClick={toggleShowAll}>
              Show All
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
      <section className="footer-section" data-intersecting="true">
        <Footer setThemeMode={setThemeMode} theme={theme}></Footer>
      </section>
    </main>
  );
};

export default Home;
