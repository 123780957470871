import React from "react";
import "./Reviews.css";
import { FiLinkedin, FiUser } from "react-icons/fi";

const Reviews = (props) => {
  const { name, link, review, company } = props.review;
  return (
    <div className="reviews-card">
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "2rem",
              alignItems: "center",
            }}
          >
            {" "}
            <FiUser className="user-icon"></FiUser>
            <div>
              <h3 className="reviewer-name">{name}</h3>
            </div>
          </div>

          <a href={link} className="review-linkedin" aria-label="Linkedin page of the reviewer">
            <FiLinkedin />
          </a>
        </div>

        <div>
          {review.map((rev, i) => (
            <p key={i}>{rev}</p>
          ))}
        </div>
      </div>
      <p
        style={{
          fontFamily: "var(--font-mono)",
          fontSize: "var(--font-xs)",
        }}
      >
        {company}
      </p>
    </div>
  );
};

export default Reviews;
