import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./TabsWrapper.css";
import gsap from "gsap";

const TabsWrapper = () => {
  const tabs = useRef();
  const [tab, setTab] = useState(0);

  useEffect(() => {
    let ctx = gsap.context(() => {
      var tx = gsap.timeline();
      tx.set("li, p", { opacity: 0 }).set("li, p", { x: 400 }).to("li, p", {
        x: 0,
        opacity: 1,
        duration: 0.1,
        stagger: 0.1,
      });
    }, tabs);

    return () => ctx.revert();
  }, [tab]);

  const handleTabChange = (_, key) => {
    if (key === tab) return;

    let ctx = gsap.context(() => {
      var tx = gsap.timeline();
      tx.fromTo(
        "li",
        { opacity: 1, x: 0 },
        {
          x: 400,
          opacity: 0,
          duration: 0.1,
          stagger: 0.1,
          onComplete() {
            setTab(key);
          },
        }
      );
    }, tabs);

    return () => ctx.revert();
  };

  const experienceObj = [
    {
      tabLabel: "HCL Technologies",
      panelHeader: "Javascript Software Engineer",
      timeFrame: "2021 - present",
      description: [
        "Clients include a multinational finance bank with millions of customers",
        "Achieved up to an 85% increase in performance for some applications",
        "Write modern, high-performing, and easily maintainable code for a variety of client and internal projects.",
        "Developing UI web components, emphasizing reusability and accessibility",
        "Daily communication with cross-functional teams comprising engineers, designers, producers, and clients.",
        "Managing the user flow between pages",
      ],
    },
    {
      tabLabel: "Freelancing",
      panelHeader: "",
      timeFrame: "2020 - present",
      description: [
        "Wordpress development",
        "Creating e-commerce pages for clients, and plugins for it",
        "Implementing eye-catching animations for customers",
        "Refactor apps and make it responsive",
      ],
    },
    {
      tabLabel: "MotionTech",
      panelHeader: "Front End Developer",
      timeFrame: "2021",
      description: [
        "Implementing responsive web and mobile design, using React",
        "Implementing backend services with PHP, and node.js",
        `Creating application for a local market with stock manipulation using CRUD operations and Rest Api's`,
        "Wordpress development for plugins, using hooks",
        "Designing the UI/UX and managing the SEO",
        "Creating graphical interfaces and statistical charts",
      ],
    },
    {
      tabLabel: "Exotic-K",
      panelHeader: "Web Technician",
      timeFrame: "2014 - 2021",
      description: [
        "Creating presentation and landing pages, using HTML,CSS, Javascript",
        "Plan marketing strategies",
        "Building up and maintaining relationships with clients around the world. For example Germany, East Europe",
      ],
    },
  ];
  return (
    <div className="tab-container">
      <div className="tap-companies">
        {experienceObj.map((exp, i) => (
          <div
            key={i}
            className={`company ${i === tab ? "selected" : ""}`}
            onClick={(event) => handleTabChange(event, i)}
          >
            {exp.tabLabel}
          </div>
        ))}
      </div>

      <div className="experience-tab-wrapper" ref={tabs}>
        <h3>
          {experienceObj[tab].panelHeader
            ? experienceObj[tab].panelHeader + " @ "
            : ""}
          <span>{experienceObj[tab].tabLabel}</span>
        </h3>
        <p>{experienceObj[tab].timeFrame}</p>
        <ul className=" tabsUi list">
          {experienceObj[tab].description.map((des, i) => (
            <li key={i}>{des}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TabsWrapper;
