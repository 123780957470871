import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import React, { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Sides from "./components/Sides";
import SplashScreen from "./components/SplashScreen";
import AnimationWrapper from "./components/AnimationWrapper";

function App() {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark"
  );

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const setThemeMode = (theme) => {
    setTheme(theme);
  };

  return (
    <BrowserRouter data-theme={theme} className="App">
      <style>
        {`
          body {
            background: ${
              theme === "dark" ? "var(--color-navy)" : "var(--color-light-blue)"
            };
          }
        `}
      </style>
      <AnimationWrapper theme={theme}>
        <SplashScreen theme={theme} />
        <Navbar theme={theme} />
        <Sides theme={theme} />
        <Routes>
          <Route
            path="/"
            element={<Home setThemeMode={setThemeMode} theme={theme} />}
          ></Route>
        </Routes>
      </AnimationWrapper>
    </BrowserRouter>
  );
}

export default App;
