import React, { useLayoutEffect, useState } from "react";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import "./Navbar.css";
import Headroom from "react-headroom";
import CV from "../assets/solyomDeniel.pdf";
import { FiFacebook, FiInstagram, FiLinkedin } from "react-icons/fi";
import HamburgerMenu from "./HamburgerMenu";
import logoLight from "../assets/logo-light.svg";
import logoDark from "../assets/logo-dark.svg";
import gsap from "gsap";

const Navbar = ({ theme }) => {
  const menu = useRef();
  const [scrolled, setScrolled] = useState(false);
  const [showNavMobile, setShowNavMobile] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderContactInfo = (type) => {
    if (type === "mobile") {
      return (
        <div className="navigation-wrapper">
          {" "}
          <ul className="nav-contact-info">
            <li className="header-element" data-animation="navigation">
              <a href="https://www.linkedin.com/in/deniel-solyom/">
                <FiLinkedin />
              </a>
            </li>
            <li className="header-element" data-animation="navigation">
              <a href="https://www.facebook.com/deniel.solyom">
                <FiFacebook />
              </a>
            </li>
            <li className="header-element" data-animation="navigation">
              <a href="https://www.instagram.com/denielsolyom">
                <FiInstagram />
              </a>
            </li>
          </ul>
          <a href="mailto:contact@denielsolyom.com">contact@denielsolyom.com</a>
        </div>
      );
    }
  };

  const handleNavButtonClick = () => {
    setShowNavMobile(!showNavMobile);
    // let ctx2 = gsap.context(() => {
    //   var tx2 = gsap.timeline();

    //   if (showNavMobile) {
    //     // Closing animation
    //     tx2.fromTo(
    //       "li",
    //       {
    //         opacity: 1,
    //       },
    //       {
    //         opacity: 0,
    //         duration: 0.3,
    //         stagger: 0.25,
    //         onComplete: () => {
    //           console.log("close menu");
    //           setShowNavMobile(false);
    //         },
    //       }
    //     );
    //   }
    //   if (!showNavMobile) {
    //     setShowNavMobile(true);
    //     tx2.fromTo(
    //       "li",
    //       { opacity: 0 },
    //       {
    //         opacity: 1,
    //         duration: 0.3,
    //         stagger: 0.25,
    //       }
    //     );
    //   }
    // }, menu);

    // return () => ctx2.revert();
  };

  const renderNav = (type, theme) => {
    return (
      <header
        ref={menu}
        className={`sticky-header${scrolled ? " scrolled" : ""} ${
          showNavMobile ? " navigation-mobile" : ""
        }`}
      >
        <div className="header-element" data-animation="navigation">
          <Link
            to="intro"
            spy={true}
            smooth={true}
            offset={-140}
            duration={500}
            aria-label="Introduction"
            onClick={type === "mobile" ? handleNavButtonClick : undefined}
          >
            <img
              className="logo"
              src={theme === "dark" ? logoLight : logoDark}
            />
          </Link>
        </div>

        <nav className="navBar">
          <ul>
            <li className="header-element" data-animation="navigation">
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={-140}
                duration={500}
                onClick={type === "mobile" ? handleNavButtonClick : undefined}
                aria-label="About me section"
              >
                .aboutMe()
              </Link>
            </li>
            <li className="header-element" data-animation="navigation">
              <Link
                to="experience"
                spy={true}
                smooth={true}
                offset={-140}
                duration={500}
                onClick={type === "mobile" ? handleNavButtonClick : undefined}
                aria-label="My experience section"
              >
                .experience()
              </Link>
            </li>
            <li className="header-element" data-animation="navigation">
              <Link
                to="testimonials"
                spy={true}
                smooth={true}
                offset={-140}
                duration={500}
                onClick={type === "mobile" ? handleNavButtonClick : undefined}
                aria-label="Testimonials section"
              >
                .testiomonials()
              </Link>
            </li>
            <li className="header-element" data-animation="navigation">
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-140}
                duration={500}
                onClick={type === "mobile" ? handleNavButtonClick : undefined}
                aria-label="Navigation"
              >
                .contact()
              </Link>
            </li>
            <li className="header-element" data-animation="navigation">
              <a
                href={CV}
                className="button"
                target="_blank"
                aria-label="My resume"
              >
                Resume
              </a>
            </li>
            {renderContactInfo(type)}
          </ul>
        </nav>
      </header>
    );
  };

  return (
    <div data-theme={theme}>
      <div
        className={`nav-hamburger ${showNavMobile ? " open" : " closed"}`}
        onClick={handleNavButtonClick}
      >
        <HamburgerMenu showNavMobile={showNavMobile} aria-label="Main menu" />
      </div>
      <div
        className={`overlay ${showNavMobile ? " open" : " closed"}`}
        onClick={handleNavButtonClick}
      ></div>
      <div className={`nav-mobile${showNavMobile ? " open" : " closed"}`}>
        {renderNav("mobile", theme)}
      </div>
      <Headroom
        style={{ zIndex: "200" }}
        downTolerance={5}
        className="headroom"
      >
        {renderNav("desktop", theme)}
      </Headroom>
    </div>
  );
};

export default Navbar;
