import React, { useEffect } from "react";
import lightLogo from "../assets/logo-light.svg";
import darkLogo from "../assets/logo-dark.svg";

const SplashScreen = ({ theme }) => {
  return (
    <div
      data-animation="splash-wrapper"
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
        zIndex: "9999",
        backgroundColor: `${
          theme === "dark" ? "var(--color-navy)" : "var(--color-light-slate)"
        }`,
      }}
    >
      <img
        data-animation="splash-logo"
        src={theme === "dark" ? lightLogo : darkLogo}
        style={{ width: "4rem", transform: "scale(20) rotate(180deg)" }}
      />
    </div>
  );
};

export default SplashScreen;
