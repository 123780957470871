import React, { useState, useEffect } from "react";
import "./MouseFollower.css";

const circleStyle = {
  width: "50px",
  height: "50px",
  background: "rgb(100,255,218)",
  background:
    "radial-gradient(circle, hsl(51, 100%, 50%) 4%, rgba(100,255,218,1) 10%, rgba(136,146,176,0.7035189075630253) 15%, rgba(35,53,84,1) 52%, rgba(17,34,64,1) 77%, rgba(10,25,47,1) 100%)",
  borderRadius: "50%",
  position: "fixed",
  opacity: 0.1,
  filter: "blur(10px)",
  zIndex: 0,
  pointer: "none",
};

const MouseFollower = () => {
  const [position, setPosition] = useState({ x: 500, y: 500 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX - 25, y: e.clientY - 25 });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      className={"mouse-follower"}
      style={{ ...circleStyle, left: position.x, top: position.y }}
    ></div>
  );
};

export default MouseFollower;
