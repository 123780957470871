import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import MouseFollower from "./MouseFollower";

const AnimationWrapper = ({ children, theme }) => {
  const app = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      var tl = gsap.timeline();

      tl.to(
        "[data-animation='splash-logo']",
        { scale: 3, rotate: 360, duration: 1.2, ease: "bounce" },
        "<"
      );
      tl.to("[data-animation='splash-logo']", {
        scale: 0,
        duration: 0.3,
        delay: 0.5,
      });
      tl.to("[data-animation='splash-wrapper']", { opacity: 0, duration: 0.5 });
      tl.set("[data-animation='splash-wrapper']", { display: "none" });
      tl.fromTo(
        "[data-animation='intro-section'] > *:not(.hexagon)",
        { opacity: 0, x: 300 },
        {
          x: 0,
          opacity: 1,
          duration: 0.3,
          stagger: 0.2,
          ease: "ease",
        },
        "<"
      );
      tl.fromTo(
        "[data-animation='navigation']",
        { opacity: 0, y: -200 },
        {
          y: 0,
          opacity: 1,
          duration: 0.3,
          stagger: 0.2,
          ease: "power2.out",
        },
        "="
      );
      // const handleIntersection = (entries) => {
      //   entries.map((entry) => {
      //     var intersecting = gsap.timeline();
      //     if (!entry.isIntersecting) {
      //       intersecting.from(entry.target, {
      //         opacity: 0,
      //         duration: 0.4,
      //         delay: 0.1,
      //       });
      //     } else {

      //     }
      //   });
      // };

      // const observer = new IntersectionObserver(handleIntersection, {
      //   threshold: 0.5,
      // });

      // const sections = app.current.querySelectorAll(
      //   '[data-intersecting="true"]'
      // );
      // sections.forEach((section) => observer.observe(section));
    }, app);

    return () => ctx.revert();
  }, []);

  return (
    <div
      ref={app}
      style={{ maxWidth: "100vw", position: "relative", overflow: "hidden" }}
      data-theme={theme}
    >
      <MouseFollower theme={theme} />
      {children}
    </div>
  );
};

export default AnimationWrapper;
